import React from 'react';
import HeaderSection from './HeaderSection';
import ServiceStatusSection from './ServiceStatusSection';

const LiveReport = () => {
    return (
        <div className='live-report-container'>
            <div className='live-report-title-row'>
                <hr className='line-title'></hr>
                <h1>Live Report</h1>
            </div>
            <div className='live-report-cards-row'>
                <HeaderSection />
            </div>
            <div className='service-status-row' id='service-status'>
                <ServiceStatusSection/>
            </div>
        </div>
    );
}

export default LiveReport;