import React, { Component } from 'react';
import logo from '../../images/logo.svg';
import ButtonsSign from './ButtonsSign';
import DropdownUser from './DropdownUser';
import { Navbar, Nav } from 'react-bootstrap';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import {
    changeCurrentSection
} from "../../actions/generalActions";
import ReactGA4 from 'react-ga4';
import DarkModeToggle from './DarkModeToggle';

class NavbarWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: 'Home',
            legalActiveItem: ''
        }

        this.onClickLink = this.onClickLink.bind(this);
        this.onClickLegalLink = this.onClickLegalLink.bind(this);
        this.ref = React.createRef();
    }

    onClickLink(id, activeName) {
        if (this.props.currentSection != activeName) {
            this.props.changeCurrentSection(activeName);
            if (this.props.cookieUp)
                ReactGA4.send({ hitType: "pageview", title: activeName, page: '/' + activeName });
        }

        this.setState({ activeItem: activeName });
        let element = document.getElementById(id);
        if (element != null)
            element.scrollIntoView({ behavior: "smooth" });

        let collapse = document.getElementById("responsive-navbar-nav")

        if(collapse != null && collapse.className === "navbar-collapse collapse show" && this.ref.current != null)
            this.ref.current.click();
    }

    onClickLegalLink(legalActiveItem) {
        this.setState({ legalActiveItem: legalActiveItem });

        let element = document.getElementById("privacy-section");
        if (element != null)
            element.scrollIntoView({ behavior: "smooth" });

        let collapse = document.getElementById("responsive-navbar-nav")

        if(collapse != null && collapse.className === "navbar-collapse collapse show" && this.ref.current != null)
            this.ref.current.click();
    }

    componentDidMount(){
        let pathname = window.location.pathname;

        if(pathname == "/cookies-policy")
            this.setState({ legalActiveItem: "/cookies-policy" });
        else
        if(pathname == "/privacy-policy")
            this.setState({ legalActiveItem: "/privacy-policy" });
        else
        if(pathname == "/terms-of-use-and-conditions")
            this.setState({ legalActiveItem: "/terms-of-use-and-conditions" });
    }

    componentWillUnmount() {
        window.onpopstate = null;
    }

    render() {
        let signData = null;

        if (this.props.fetchedGetUserData && this.props.userData != null)
            signData = <DropdownUser />;
        else
            signData = <ButtonsSign />

        let pathName = window.location.pathname;

        return (
            <Navbar collapseOnSelect expand="lg" className="navCoowise navbar-static-top">
                <Navbar.Brand 
                    onClick=
                    {
                        pathName != "/cookies-policy" && pathName != "/privacy-policy" && pathName != "/terms-of-use-and-conditions" &&
                        pathName != "/blog" && pathName != "/satisfaction-survey" && pathName != "/api-reference" && pathName != "/live-report" 
                        && pathName != "/tender-notify-beta-closed"
                            ? () => this.onClickLink("home", "Home") 
                            : () => {window.location.pathname = "/"}
                    }
                >
                    <img className="cursor" src={logo} alt="tender-notify-logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" ref={this.ref}/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto nav-coowise">
                        {
                            pathName != "/cookies-policy" && pathName != "/privacy-policy" && pathName != "/terms-of-use-and-conditions" &&
                            pathName != "/blog" && pathName != "/satisfaction-survey" && pathName != "/api-reference" && pathName != "/live-report"
                                ?
                                    <>
                                        <Link to={pathName != "/" ? "/#home" : "/"}>
                                            <p class="nav-link" onClick={() => this.onClickLink("home", "Home")}>Home</p>
                                        </Link>
                                        <Link to={pathName != "/" ? "/#exploreResources" : "/"} onClick={() => this.onClickLink("exploreResources", "ExploreResources")}>
                                            <p class="nav-link" >Explore Our Resources</p>
                                        </Link>
                                        <Link to={pathName != "/" ? "/#features" : "/"} onClick={() => this.onClickLink("features", "Features")}>
                                            <p class="nav-link" >Features</p>
                                        </Link>
                                        <Link to={pathName != "/" ? "/#pricing" : "/"} onClick={() => this.onClickLink("pricing", "Pricing")}>
                                            <p class="nav-link" >Pricing</p>
                                        </Link>
                                        <Link to={pathName != "/" ? "/#faqs" : "/"} onClick={() => this.onClickLink("faqs", "FAQs")}>
                                            <p class="nav-link" >FAQs</p>
                                        </Link>
                                        <Link to={pathName != "/" ? "/#contact" : "/"} onClick={() => this.onClickLink("contact", "ContactUs")}>
                                            <p class="nav-link" >Contact Us</p>
                                        </Link>
                                    </>
                                :   pathName != "/blog" && pathName != "/satisfaction-survey" && pathName != "/api-reference" && pathName != "/live-report"
                                        ?
                                            <>
                                                <Link to="/terms-of-use-and-conditions">
                                                    <p 
                                                        class={"nav-link " + (this.state.legalActiveItem == "/terms-of-use-and-conditions" ? "selected" : "")} 
                                                        onClick={() => this.onClickLegalLink("/terms-of-use-and-conditions")}
                                                    >
                                                        Terms of use and conditions
                                                    </p>
                                                </Link>
                                                <Link to="/privacy-policy">
                                                    <p 
                                                        class={"nav-link " + (this.state.legalActiveItem == "/privacy-policy" ? "selected" : "")} 
                                                        onClick={() => this.onClickLegalLink("/privacy-policy")}
                                                    >
                                                        Privacy policy
                                                    </p>
                                                </Link>
                                                <Link to="/cookies-policy">
                                                    <p 
                                                        class={"nav-link " + (this.state.legalActiveItem == "/cookies-policy" ? "selected" : "")} 
                                                        onClick={() => this.onClickLegalLink("/cookies-policy")}
                                                    >
                                                        Cookie Policy
                                                    </p>
                                                </Link>
                                            </>
                                        :
                                            pathName != "/satisfaction-survey"
                                                ?
                                                    <>
                                                        <Link to={"/"}>
                                                            <p class="nav-link" onClick={() => this.onClickLink("home", "Home")}>Home</p>
                                                        </Link>
                                                        <Link to={"/blog"} onClick={() => this.onClickLink("blog-div", "Blog")}>
                                                            <p class="nav-link">Tender Notify Blog</p>
                                                        </Link>
                                                        <Link to={"/api-reference"} onClick={() => this.onClickLink("blog-div", "API-reference")}>
                                                            <p class="nav-link">API Reference</p>
                                                        </Link>
                                                        
                                                    </>
                                                : null

                        }
                    </Nav>
                    {
                        pathName != "/cookies-policy" && pathName != "/privacy-policy" && pathName != "/terms-of-use-and-conditions" &&
                        pathName != "/blog" && pathName != "/api-reference" && pathName != "/live-report"
                            ? <>{signData}</>
                            : pathName == "/blog" || pathName == "/api-reference" || pathName == "/cookies-policy" || pathName == "/privacy-policy" || 
                                pathName == "/terms-of-use-and-conditions"
                                ? <DarkModeToggle/>
                                : null
                    }
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp,

        userData: value.security.userData,
        fetchedGetUserData: value.security.fetchedGetUserData
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarWeb);